import { ClassName } from "~/utils";

export default function Logo({ className }: ClassName) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="178.813"
      height="44.251"
      version="1.1"
      viewBox="0 0 47.311 11.708"
    >
      <g transform="translate(-52.599 -109.47)">
        <g transform="rotate(135 71.846 110.03)">
          <path
            fill="none"
            stroke="currentColor"
            strokeLinejoin="bevel"
            strokeWidth="0.7"
            d="M81.081 109.73h-3.903"
          ></path>
          <path
            fill="currentColor"
            d="M75.492 107.34a2.393 2.393 0 00-2.39 2.39 2.393 2.393 0 002.39 2.391h2.66v-.701h-2.66a1.679 1.679 0 01-1.691-1.69 1.68 1.68 0 011.691-1.69h2.661v-.7zm4.613 0v.7h2.66a1.68 1.68 0 011.692 1.69c0 .943-.75 1.69-1.692 1.69h-2.66v.701h2.66a2.393 2.393 0 002.391-2.39 2.393 2.393 0 00-2.39-2.391z"
            color="currentColor"
          ></path>
        </g>
        <path
          style={{ textAlign: "center" }}
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0"
          d="M53.538 120.238v-9.624h.747v9.624zm.48 0v-.678h5.432v.678zm19.84 0v-9.624h.536l.211.903v8.72zm6.66 0l-6.237-8.636.113-.988 6.25 8.622zm0 0l-.212-.875v-8.749h.748v9.624zm9.412 0l-5.673-5.01 5.546-4.614h1.044l-5.814 4.812v-.438l5.955 5.25zm-6.251 0v-9.624h.747v9.624zm11.133 0v-9.356h.734v9.356zm-3.528-8.947v-.677h7.79v.677z"
          aria-label="L NKT"
          fontFamily="Outfit"
          fontSize="14.111"
          fontWeight="200"
          textAnchor="middle"
        ></path>
      </g>
    </svg>
  );
}
