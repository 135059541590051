import { Theme, useTheme } from "remix-themes";
import { Button } from "~/components/ui/button";
import { Icon } from "@iconify/react";

export default function ThemeToggleButton() {
  const [theme, setTheme] = useTheme();

  switch (theme) {
    case Theme.DARK:
      return (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setTheme(Theme.LIGHT)}
        >
          <Icon
            className="h-7 w-fit"
            icon="material-symbols-light:light-mode"
          />
        </Button>
      );
    default:
      return (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setTheme(Theme.DARK)}
        >
          <Icon className="h-7 w-fit" icon="material-symbols-light:dark-mode" />
        </Button>
      );
  }
}
