export function scrollToElement(
  elementOrId: HTMLElement | string,
  yOffset = 0,
) {
  const element =
    typeof elementOrId === "string"
      ? document.getElementById(elementOrId)
      : elementOrId;
  if (!element) {
    return;
  }
  const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}
