import { cn } from "~/lib/utils";
import { CSSProperties, ReactNode } from "react";
import { cva, VariantProps } from "class-variance-authority";

const buttonVariants = cva("px-5 md:px-8 lg:px-16", {
  variants: {
    variant: {
      wide: "",
      narrow: "max-w-[1280px] mx-auto",
    },
  },
  defaultVariants: {
    variant: "wide",
  },
});

interface ContentProps extends VariantProps<typeof buttonVariants> {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

export default function Content({
  variant,
  style,
  className,
  children,
}: ContentProps) {
  return (
    <div style={style} className={cn(buttonVariants({ variant, className }))}>
      {children}
    </div>
  );
}
