import { ClassNameAndChildren } from "~/utils";
import { cn } from "~/lib/utils";
import { Slot } from "@radix-ui/react-slot";

interface TextProps extends ClassNameAndChildren {
  asChild?: boolean;
}

export function LargeText({ asChild, className, children }: TextProps) {
  const Comp = asChild ? Slot : "span";
  return (
    <Comp
      className={cn(
        "text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-medium",
        className,
      )}
    >
      {children}
    </Comp>
  );
}

export function LargeParagrapgh({ className, children }: ClassNameAndChildren) {
  return (
    <LargeText asChild className={className}>
      <p>{children}</p>
    </LargeText>
  );
}

interface UppercaseTextProps extends ClassNameAndChildren {
  asChild?: boolean;
}
export function UppercaseText({
  asChild,
  className,
  children,
}: UppercaseTextProps) {
  const Comp = asChild ? Slot : "span";
  return (
    <Comp className={cn("font-normal uppercase", className)}>{children}</Comp>
  );
}

export function UppercaseH2({ className, children }: ClassNameAndChildren) {
  return (
    <UppercaseText asChild className={className}>
      <h2>{children}</h2>
    </UppercaseText>
  );
}
